.badge-ftdr {
    background-color: #008487;
}

.ratings>svg {
    width: 19px;
    height: 19px;
}

.ratings-bubble-ftdr .ratings>svg {
    width: 24px;
    height: 24px;
}

.ratings-text-ftdr {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    max-width: 235px;
}

@media (min-width: 1024px) {
    .ratings-text-ftdr {
        max-width: unset;
    }
}