.page404-container {
    min-height: 200px;
}

.page404-container .subtitle-page404 {
    color: #424242;
}

.page404-container .booking-widget {
    height: 105px;
}
